import React from 'react';
import { img, imgBrand, isBe, alt } from '../../lib/brand';
import HomeCinemaTop from '../cinemagraphs/HomeCinemaTop';
import CopyBlockHome from '../CopyBlockHome';
import Panel from '../Panel';
import Calcs from '../Calculators';
import NeedSomething from './components/NeedSomething';
import TestimonialsHome from '../TestimonialsHome';
import FindLO from '../FindLO';
import Accent from '../Accent';
import StaticText from '../StaticText';

var allTheEaseImg = img('man.jpg');
var wereHereToImg = imgBrand('man2.png');

import styles from './styles.module.less';

const HomePage = ({ isPreview, staticTextItems }) => {
   const [titleText, setTitleText] = React.useState('');
   return (
      <section>
         <div>
            <HomeCinemaTop />
            <div className={styles.heroCopy}>
               <div className={styles.inner}>
                  <div className={styles.homeHero}>
                     {isBe ? (
                        <>
                           <div className={styles.title}>
                              We&#8217;re here
                              <br /> to get you home
                           </div>
                           <Accent align="left" />
                        </>
                     ) : (
                        <div className={styles.noTitle}></div>
                     )}
                     <div>
                        <StaticText id="home-header" items={staticTextItems} />
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className={styles.PanelBlock}>
            <Panel />
         </div>
         <CopyBlockHome
            title="All the ease of online"
            description={<StaticText id="home-all-ease" items={staticTextItems} className={styles.homeAllEase} />}
            buttonTitle={'Find Your Team'}
            href="/find-your-loan-officer"
            icon={allTheEaseImg}
            flexD="row-reverse"
            alt={alt('Man Sitting on Couch Working on Laptop - Online Home Loan Process')}
            showLink={isBe}
         />
         {isBe && (
            <Calcs
               key="calculators"
               showCalculatorMortgage={true}
               showCalculatorRentVsBuy={true}
               showCalculatorHomeAffordability={true}
               showCalculatorRequiredIncome={true}
               showCalculatorRefinance={true}
               isPreview={isPreview}
               staticTextItems={staticTextItems}
            />
         )}
         <NeedSomething />
         {isBe && (
            <>
               <FindLO />
               <CopyBlockHome
                  className={styles.wereHome}
                  title={
                     <>
                        We&#8217;re here
                        <br /> to get you home
                     </>
                  }
                  description={<StaticText id="home-were-here" items={staticTextItems} />}
                  buttonTitle={'Learn More'}
                  href="/about-us"
                  icon={wereHereToImg}
                  flexD="row"
                  alt={alt('Man Wearing Hat at the Park - Full-Service Home Mortgage Lender')}
               />
               <TestimonialsHome />
            </>
         )}
      </section>
   );
};

export default HomePage;
