import React from 'react';
import Accent from '../Accent';
import LinkButton from '../LinkButton';
import styles from './styles.module.less';

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

interface CopyBlockHomeProps {
   icon: string;
   title: string;
   description: string;
   flexD?: FlexDirection;
   buttonTitle: string;
   name: string;
   href: string;
   alt: string;
   showLink?: boolean;
}

const CopyBlockHome = (props: CopyBlockHomeProps) => {
   const { icon, title, description, flexD, buttonTitle, name, href, alt, showLink = true } = props;
   return (
      <section className={styles.CopyBlock}>
         <div className={styles.container} style={{ flexDirection: flexD }} id={name}>
            <div className={styles.image}>
               <img className={styles.icon} src={icon} alt={alt} />
            </div>
            <div className={styles.text}>
               <h1 className={styles.title}>{title}</h1>
               <Accent align="left" />
               <div className={styles.description}>{description}</div>
               {showLink && (
                  <div className={styles.copyButton}>
                     <LinkButton label={buttonTitle} href={href} />
                  </div>
               )}
            </div>
         </div>
      </section>
   );
};

export default CopyBlockHome;

CopyBlockHome.defaultProps = {
   bgColor: '#FFFFFF',
   flexD: 'row'
};
