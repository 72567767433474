import React, { Component } from 'react';
import styles from './styles.module.less';
import { img } from '../../../lib/brand';

var background = img('NeedSomething.jpg');

class HomeCinemaMid extends Component {
   render() {
      let backVideo = 'https://ucarecdn.com/d3ef8b2b-6fe1-43ef-808b-073d24046b1c/Waves_Cinegraph.mp4';

      return (
         <section className={styles.HomeCinemaMid1}>
            <div className={styles.bgVideo}>
               <video className={styles.back} ref={this.backRef} loop autoPlay muted playsInline>
                  <source src={backVideo} type="video/mp4" />
                  <source src={backVideo} type="video/ogg" />
                  <img src={background} title="Your browser does not support the video tag." alt="" />
               </video>
            </div>
         </section>
      );
   }
}

export default HomeCinemaMid;
