import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { siteNameEnglish, brandSlogan, siteNameShort } from '../lib/brand';
import Layout from '../components/Layout';
import Header from '../components/Header';
import HomePage from '../components/HomePage';
import Helmet from 'react-helmet';

const NotFoundPage = () => {
   useEffect(() => {
      async function redirect() {
         // Redirect to correct "home" page based on referrer location
         let siteFound = null;
         pageNotFoundSites.forEach((s, i) => {
            if (window.location.pathname.includes(s)) {
               siteFound = pageNotFoundSites[i];
            }
         });
         if (siteFound) {
            await navigate(siteFound);
            return null;
         }
      }
      redirect();
   }, []);

   const header = <Header active={[]} theme="light" />;

   return (
      <Layout hasHero={true} header={header}>
         <Helmet>
            <title>{`${siteNameEnglish} | ${brandSlogan}`}</title>
            <meta
               name="description"
               content={`${siteNameShort} is a full-service home mortgage lender. From first-time home loans to home refinancing, we can help with whatever your home loan needs may be. Contact us today.`}
            />
            <script
               src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
         </Helmet>
         <HomePage />
      </Layout>
   );
};

export default NotFoundPage;

const pageNotFoundSites = ['/giving-to-heroes', '/corporate-care', '/community-care'];
